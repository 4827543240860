import React, { SVGProps, useId } from 'react';

export const BnbChain = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();

  return (
    <svg viewBox="0 0 17 16" {...props}>
      <g clip-path={`url(#${id})`}>
        <path
          d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8Z"
          fill="#F0B90B"
        />
        <path
          d="M11.5674 7.52863L11.5612 6.19701H11.566L10.4349 5.53119L8.4968 6.67396L6.57038 5.53119L5.44002 6.19701V7.52863L7.37817 8.66588V10.9459L8.5037 11.6055L9.62923 10.9459V8.66588L11.5674 7.52863Z"
          fill="white"
        />
        <path
          d="M8.49749 2.12012L5.43933 3.92318L6.56486 4.589L8.49749 3.44623L10.4356 4.589L11.5612 3.92318L8.49749 2.12012Z"
          fill="white"
        />
        <path
          d="M4.63173 10.2746L4.62553 8.00006L3.50069 7.33494V10.9466L6.56436 12.7434V11.4118L4.63173 10.2746Z"
          fill="white"
        />
        <path
          d="M4.62553 7.05097V5.72555L5.75657 5.05974L4.62553 4.39392L3.5 5.05974V6.38516L4.62553 7.05097Z"
          fill="white"
        />
        <path
          d="M8.49699 4.39392L7.37146 5.05974L8.49699 5.72555L9.62804 5.05974L8.49699 4.39392Z"
          fill="white"
        />
        <path d="M6.56486 9.13731L5.43933 8.4715V9.80313L6.56486 10.4627V9.13731Z" fill="white" />
        <path
          d="M8.49749 12.5546L7.37196 11.8888V13.2142L8.49749 13.88L9.62854 13.2142V11.8888L8.49749 12.5546Z"
          fill="white"
        />
        <path
          d="M12.3683 4.39392L11.2427 5.05974L12.3683 5.72555V7.05097L13.4993 6.38516V5.05974L12.3683 4.39392Z"
          fill="white"
        />
        <path
          d="M13.5 7.33425L12.3745 8.00006L12.3683 10.2746L10.4363 11.4111V12.7428L13.5 10.9459V7.33425Z"
          fill="white"
        />
        <path d="M11.5614 9.80308L10.4359 10.4627V9.13727L11.5614 8.47146V9.80308Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_9054_38822">
          <path
            d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
